import { Link } from "@fluentui/react";
import { ITDFTenantLeaseGroup } from "../api/api.types";
import { getAndFormatDate } from "../utils/dateUtils";
import CopyIconButton from "../components/common/CopyIconButton";
import { RenderTextOptions } from "../constants/interfaces";
import {
  textLinkCopyContainerStyle,
  textLinkCopyTextStyle,
} from "../constants/styles";

export const findTenant = (
  tenants: ITDFTenantLeaseGroup[] | undefined,
  tenantId: string
): ITDFTenantLeaseGroup | undefined => {
  return tenants?.find((t) => t.TenantId === tenantId);
};

export const renderDate = <T extends Record<string, any>>(
  object: T,
  dateKey: keyof T
) => {
  const dateValue = object[dateKey];
  if (dateValue) {
    return getAndFormatDate(dateValue);
  }
};

export const renderTextLinkCopy = (
  text: string,
  options: RenderTextOptions = {}
) => {
  const { url, showCopy = true } = options;

  return (
    <div style={textLinkCopyContainerStyle}>
      <div style={textLinkCopyTextStyle}>
        {url ? <Link href={url}>{text}</Link> : text}
      </div>
      {showCopy && <CopyIconButton copyText={text} />}
    </div>
  );
};

export const getStringValueOrNull = (
  value: string
) => {
  return (value.trim() === '') ? null : value;
}

export const serializeProvisioningConfigurations = (
  isParentProfileTenantBase: boolean,
  isCreatingTypeTenantPool: boolean,
  exchangeServiceInstance: string,
  selectedTenantSku: string | null,
  plannerServiceInstance: string,
  spoServiceInstance: string,
  poolName: string,
  dailyQuota: number | null,
  usageLocation: string
) =>  {
    
    const provisioningConfiguration = {
      "ExchangeServiceInstance": getStringValueOrNull(exchangeServiceInstance),
      "TenantSku" : selectedTenantSku,
      "PlannerServiceInstance": getStringValueOrNull(plannerServiceInstance),
      "SpoServiceInstance": getStringValueOrNull(spoServiceInstance),
      "TargetPool": poolName,
      "DailyQuota": dailyQuota,
      "UsageLocation": getStringValueOrNull(usageLocation)
    }
    return (isParentProfileTenantBase && isCreatingTypeTenantPool) ? [JSON.stringify(provisioningConfiguration)] : [];
  };
 