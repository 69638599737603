import { IDropdownOption, IGroup } from "@fluentui/react";
import _ from "lodash";

export const FormatTime = (timeString: string): JSX.Element => {
  const time = new Date(timeString);
  return <span>{time.toLocaleString()}</span>;
};

export const FormatDateTime = (
  dateTime: Date | string | null | undefined
): JSX.Element => {
  if (dateTime == null) {
    return <span>N/A</span>;
  }

  let dateObj;

  // If dateTime is a string, try to parse it as a Date
  if (typeof dateTime === "string") {
    dateObj = new Date(dateTime);
    // Check if the date is valid
    if (isNaN(dateObj.getTime())) {
      return <span>Invalid Date</span>;
    }
  } else if (dateTime instanceof Date) {
    // If dateTime is already a Date object, use it directly
    dateObj = dateTime;
  } else {
    // If dateTime is neither a string nor a Date, return as invalid
    return <span>Invalid Date</span>;
  }

  // Format the date
  const formatString = dateObj.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return <span>{formatString}</span>;
};

export function CopyAndSortTableRows<T extends object>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean,
  propertiesToGroupBy?: (keyof T)[]
): T[] {
  if (items.length === 0) return items;

  const key = columnKey as keyof T;

  if (!Object.hasOwn(items[0], columnKey)) {
    const validKeys = Object.keys(items[0]).join(", ");
    // eslint-disable-next-line no-console
    console.error(
      `Unable to sort items by "${key.toString()}" since it's not a valid property of the object. Valid properties are: ${validKeys}`
    );
    return items;
  }

  const sorted = items.slice(0).sort((a: T, b: T) => {
    const valueA = a[key];
    const valueB = b[key];

    if (typeof valueA === "string" && typeof valueB === "string") {
      const stringA = (valueA as string).toLowerCase();
      const stringB = (valueB as string).toLowerCase();

      return (isSortedDescending ? stringA < stringB : stringA > stringB)
        ? 1
        : -1;
    } else if (!valueA && valueB) {
      // if valueA is either null or undefined, we want to say that valueB
      // is greater than valueA, so we return 1
      return 1;
    } else if (valueA && !valueB) {
      // if valueB is either null or undefined, we want to say that valueA
      // is greater than valueB, so we return -1
      return -1;
    }

    return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
  });

  // If properties were provided to group results in a table by, sort the sorted list by those properties
  if (propertiesToGroupBy) {
    return _.sortBy(sorted, propertiesToGroupBy);
  } else {
    return sorted;
  }
}

export const arrayToDropdownOptions = (
  uniqueValues: string[]
): IDropdownOption[] => {
  const options: IDropdownOption[] = uniqueValues.map((value) => {
    return {
      key: value,
      text: value,
    };
  });
  return options;
};

/**
 * Helper method utilized by Extended Details List to recursively generate groups for the items based on a provided
 * array of keys to group the items by.
 *
 * @param items the items to generate FluentUI groups for
 * @param propertiesToGroupOn the properties to group the items by in order
 * @param count [used via recursion] used to determine the number of items that have been encountered so far
 * @param level [used via recusion] used to keep track of the group levels so proper indentation of the grouped list occurs.
 * @returns the generated group descriptors the items based on the properties provided to group on.
 */
export function generateGroups<T>(
  items: T[],
  propertiesToGroupOn: (keyof T)[],
  count = 0,
  level = 0
): IGroup[] | undefined {
  const copyOfPropertiesToGroupOn = [...propertiesToGroupOn];
  const property = copyOfPropertiesToGroupOn.shift();
  if (!property) return undefined;

  const result = _(items)
    .sortBy((t) => t[property])
    .groupBy((t) => t[property])
    .map<IGroup>((groupedItems, propertyValue) => {
      const group: IGroup = {
        key: propertyValue,
        name: propertyValue,
        level,
        startIndex: count,
        count: groupedItems.length,
        children: generateGroups(
          groupedItems,
          copyOfPropertiesToGroupOn,
          count,
          level + 1
        ),
      };

      count += groupedItems.length;

      return group;
    })
    .value();

  return result;
}
