import {
  DetailsList,
  Dropdown,
    IconButton,
    IDropdownOption,
    MessageBarType,
    PrimaryButton,
    Spinner,
    Stack,
    TextField,
    Toggle,
  } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useMessageHandler } from "../../common/useMessageHandler";
import TenantSkuSelector from "./TenantSkuSelector";
import { TenantBase, tenantSkuOptions, TenantPool, assetPoolType, BusinessUserMailbox} from "../../../constants/constants";
import ExtendableMessageBar from "../../common/ExtendableMessageBar";
import { serializeProvisioningConfigurations } from "../../../functions/helpers";
import { HydrationTargetColumns } from "./HydrationTargets.columns";
import { HydrationTarget, IPool } from "../../../constants/interfaces";
  
export interface ICreateOrUpdateAssetPoolProps {
  managePool: (
    poolName: string,
    parentProfile: string,
    targetPoolSize: number,
    cost: number,
    provisionBufferHours: number,
    isReservable: boolean,
    isDataProvisioned: boolean,
    isReusable: boolean,
    provisioningConfigurations: string[],
    hydrationTargets: HydrationTarget[]) => Promise<void>,
    action: string,
    assetPool?: IPool,
};

const CreateOrUpdateAssetPool: React.FC<ICreateOrUpdateAssetPoolProps> = ({
  managePool,
  action,
  assetPool
}) => {  
  const {
    defaultMessage,
    additionalMessage,
    messageType,
    resetMessage,
    showMessage,
  } = useMessageHandler();

  let poolProvisioningConfig = null;

  if (assetPool) {
    if (assetPool.ProvisioningConfigurations && assetPool.ProvisioningConfigurations.length > 0 && assetPool.ProvisioningConfigurations[0] !== '')
    {
      poolProvisioningConfig = JSON.parse(assetPool.ProvisioningConfigurations[0]); // ANNA: double check we only care about 1 provisioning config        
      if (poolProvisioningConfig.DailyQuota) dailyQuota = poolProvisioningConfig.DailyQuota;
      if (poolProvisioningConfig.PlannerServiceInstance) plannerServiceInstance = poolProvisioningConfig.PlannerServiceInstance;
      if (poolProvisioningConfig.SpoServiceInstance) spoServiceInstance = poolProvisioningConfig.SpoServiceInstance;
      if (poolProvisioningConfig.TenantSku) selectedTenantSku = poolProvisioningConfig.TenantSku;
      if (poolProvisioningConfig.ExchangeServiceInstance) exchangeServiceInstance = poolProvisioningConfig.ExchangeServiceInstance;
      if (poolProvisioningConfig.UsageLocation) usageLocation = poolProvisioningConfig.UsageLocation;
    }
  }

  var [poolName, setPoolName] = useState(assetPool?.PoolName ?? "");
  var [parentProfile, setParentProfile] = useState(assetPool?.ParentProfileName ?? TenantBase);
  var [targetPoolSize, setTargetPoolSize] = useState<number>(assetPool?.TargetPoolSize ?? 1);
  const [isTargetPoolSizeValid, setIsTargetPoolSizeValid] = useState<boolean>(true);
  const [targetPoolSizeErrorMessage, setTargetPoolSizeErrorMessage] = useState("");
  var [cost, setCost] = useState<number>(assetPool?.Cost ?? 1);
  const [isCostValid, setIsCostValid] = useState<boolean>(true);
  const [costErrorMessage, setCostErrorMessage] = useState("");  
  var [provisionBufferHours, setProvisionBufferHours ] = useState<number>(assetPool?.ProvisionBufferHours ?? 0);
  const [isProvisionBufferHoursValid, setIsProvisionBufferHoursValid] = useState<boolean>(true);
  const [provisionBufferHoursErrorMessage, setProvisionBufferHoursErrorMessage] = useState("");
  var [dailyQuota, setDailyQuota] = useState<number | null>(poolProvisioningConfig?.DailyQuota ?? 1);
  const [isDailyQuotaValid, setIsDailyQuotaValid] = useState<boolean>(true);
  const [dailyQuotaErrorMessage, setDailyQuotaErrorMessage] = useState("");
  var [isReservable, setIsReservable] = useState<boolean>(assetPool?.IsReservable ?? true);
  var [isDataProvisioned, setIsDataProvisioned] = useState<boolean>(assetPool?.IsDataProvisioned ?? false);
  var [isReusable, setIsReusable] = useState<boolean>(assetPool?.IsReusable ?? true);
  var [exchangeServiceInstance, setExchangeServiceInstance] = useState(poolProvisioningConfig?.ExchangeServiceInstance ?? "<PROD>");
  var [plannerServiceInstance, setPlannerServiceInstance] = useState(poolProvisioningConfig?.PlannerServiceInstance ?? "");
  var [spoServiceInstance, setSpoServiceInstance] = useState(poolProvisioningConfig?.SpoServiceInstance ?? "");
  var [usageLocation, setUsageLocation] = useState(poolProvisioningConfig?.UsageLocation ?? "");
  var [selectedTenantSku, setSelectedTenantSku] = useState<string | null>(poolProvisioningConfig?.TenantSku ?? null); 
  const [updatedTenantSkuOptions, setUpdatedTenantSkuOptions] = React.useState(tenantSkuOptions);
  const [submittingManagePoolRequest, setSubmittingManagePoolRequest] = useState<boolean>(false);
  const [selectedAssetPoolTypeKey, setSelectedAssetPoolTypeKey] = useState<string | null>(TenantPool); //ANNA: update with account or tenant pool?
  var [hydrationTargets, setHydrationTargets] = React.useState<HydrationTarget[]>(assetPool?.HydrationTargets ?? []);
  const [newHydrationTargetProfileName, setNewHydrationTargetProfileName] = useState("");
  const [newHydrationTargetCount, setNewHydrationTargetCount] = useState<number>(1);
  const [newHydrationTargetCountErrorMessage, setNewHydrationTargetCountErrorMessage] = useState("");  
  const [isNewHydrationTargetCountValid, setIsNewHydrationTargetCountValid] = useState<boolean>(true);

const provisioningConfigurationStrings = serializeProvisioningConfigurations(
    parentProfile == TenantBase,
    selectedAssetPoolTypeKey == TenantPool,
    exchangeServiceInstance,
    selectedTenantSku,
    plannerServiceInstance,
    spoServiceInstance,
    poolName,
    dailyQuota,
    usageLocation,
  );

  const handleSubmit = async () => {
    resetMessage();
    setSubmittingManagePoolRequest(true);

    if (poolName !== "" && 
        parentProfile != "" && 
        isTargetPoolSizeValid &&
        isCostValid &&
        isProvisionBufferHoursValid)
      { 
        try {
          await managePool(
            poolName,
            parentProfile,
            targetPoolSize,
            cost,
            provisionBufferHours,
            isReservable,
            isDataProvisioned,
            isReusable,
            provisioningConfigurationStrings,
            hydrationTargets
          );
          showMessage(`Pool ${action}d Successfully.`, MessageBarType.success);
        } catch (error) {
          showMessage(
            `Error when attempting to ${action} pool...`,
            MessageBarType.error,
            `${error}`
          );
        }
      } else {
        showMessage(`Please fill in all required fields.`, MessageBarType.error);
      }
    setSubmittingManagePoolRequest(false);
  }

  const handleTextChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: (value: string) => void,
    newValue?: string,
  ): void => {
    resetMessage();
    const updatedValue =
      newValue !== undefined
        ? newValue.trim()
        : (event.target as HTMLInputElement).value;
    setValue(updatedValue);
  };

  const handleNumberInputChange = (
    setErrorMessage: (message: string) => void,
    setIsValid: (isValid: boolean) => void,
    setValue: (value: number) => void,
    newValue?: string,
    minValidValue = 0
  ): void => {
    resetMessage();
    if (newValue != null && parseInt(newValue) >= minValidValue) {
      setErrorMessage("");
      setIsValid(true);
      setValue(parseInt(newValue));
    } else {
      setErrorMessage("Please enter a value greater than or equal to: "+ minValidValue);
      setValue(minValidValue);
    }
  };

  const handleTenantSkuChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    setSelectedTenantSku(option ? (option.key as string) : null);
  };
  
  const handleAssetPoolTypeChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    let key: string|null = option ? (option.key as string) : null;

    if (key != null)
    {
      setParentProfile(key + "Base");
    }

    setSelectedAssetPoolTypeKey(key);
  };

  const handleAddingHydrationTargets = (
    newValue: HydrationTarget
  ) =>
  {
    const existingProfiles = hydrationTargets.map(target => target.ProfileName)
    if (!existingProfiles.includes(newValue.ProfileName) && newValue.ProfileName.trim() != '')
    {
      setHydrationTargets(hydrationTargets => [...hydrationTargets, newValue]);
    }
  }
  
    return (
      <>
        <Stack>
          <Dropdown
            label="Type of asset pool"
            options={assetPoolType}
            selectedKey={selectedAssetPoolTypeKey}
            onChange={handleAssetPoolTypeChange}
            styles={{
              dropdown: {
                width: "300px",
              },
            }}
          />
          <TextField
            label="Pool Name"
            value={poolName}
            onChange={(event, newValue) => handleTextChange(event, setPoolName, newValue)}
            required={true}
          />
          <TextField
            label="Parent Profile"
            value={parentProfile}
            onChange={(event, newValue) => handleTextChange(event, setParentProfile, newValue)}
            required={true}
          />
          <TextField
            label="Pool Size"
            type="number"
            value={targetPoolSize.toString()}
            min={1}
            onChange={(event, newValue) => handleNumberInputChange(setTargetPoolSizeErrorMessage, setIsTargetPoolSizeValid, setTargetPoolSize, newValue)}
            errorMessage={targetPoolSizeErrorMessage}
          />
          <TextField
            label="Cost"
            type="number"
            value={cost.toString()}
            min={0}
            onChange={(event, newValue) => handleNumberInputChange(setCostErrorMessage, setIsCostValid, setCost, newValue)}
            errorMessage={costErrorMessage}
          />
          <TextField
            label="Provision Buffer Hours"
            type="number"
            value={provisionBufferHours.toString()}
            min={0}
            onChange={(event, newValue) => handleNumberInputChange(setProvisionBufferHoursErrorMessage, setIsProvisionBufferHoursValid, setProvisionBufferHours, newValue)}
            errorMessage={provisionBufferHoursErrorMessage}
          />
          <Toggle
            label="isReservable"
            inlineLabel
            onText="True"
            offText="False"
            checked={isReservable}
            onChange={(ev, checked) => {
              setIsReservable(checked || false);
            }}
          />
          <Toggle
            label="isDataProvisioned"
            inlineLabel
            onText="True"
            offText="False"
            checked={isDataProvisioned}
            onChange={(ev, checked) => {
              setIsDataProvisioned(checked || false);
            }}
          />
          <Toggle
            label="isReusable"
            inlineLabel
            onText="True"
            offText="False"
            checked={isReusable}
            onChange={(ev, checked) => {
              setIsReusable(checked || false);
            }}
          />
          {parentProfile == TenantBase && selectedAssetPoolTypeKey == TenantPool && (
            <>
              <TextField
                label="Exchange Service Instance"
                value={exchangeServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setExchangeServiceInstance, newValue)}
                required={false} 
              />
              <TextField
                label="Spo Service Instance"
                value={spoServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setSpoServiceInstance, newValue)}
                required={false} 
              />
              <TextField
                label="Planner Service Instance"
                value={plannerServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setPlannerServiceInstance, newValue)}
                required={false} 
              />
              <TextField
                label="Usage Location"
                value={usageLocation}
                onChange={(event, newValue) => handleTextChange(event, setUsageLocation, newValue)}
                required={false} 
              />
              <TextField
                label="Daily Quota"
                type="number"
                value={dailyQuota?.toString()}
                min={0}
                onChange={(event, newValue) => handleNumberInputChange(setDailyQuotaErrorMessage, setIsDailyQuotaValid, setDailyQuota, newValue)}
                errorMessage={dailyQuotaErrorMessage} 
              />
              <TenantSkuSelector
                selectedTenantSkuKey={selectedTenantSku}
                updatedTenantSkuOptions={updatedTenantSkuOptions}
                handleTenantSkuChange={handleTenantSkuChange} 
              />
              <DetailsList
                items={hydrationTargets}
                columns={HydrationTargetColumns(hydrationTargets, setHydrationTargets)}
              />
              <Stack horizontal styles={{ root: { display: 'flex' } }}>
                <TextField
                  label="Hydration Target"
                  value={newHydrationTargetProfileName}
                  onChange={(event, newValue) => handleTextChange(event, setNewHydrationTargetProfileName, newValue)}
                  required={false} 
                />
                <TextField
                  label="Hydration Target Count"
                  defaultValue={newHydrationTargetCount?.toString()}
                  onChange={(event, newValue) => handleNumberInputChange(setNewHydrationTargetCountErrorMessage, setIsNewHydrationTargetCountValid, setNewHydrationTargetCount, newValue, 1)}
                  required={false} 
                  errorMessage={newHydrationTargetCountErrorMessage}
                />          
                <PrimaryButton
                  text="Add Hydration Target"
                  onClick={() => handleAddingHydrationTargets({
                    ProfileName: newHydrationTargetProfileName,
                    Count: newHydrationTargetCount
                  })}
                  disabled={!isNewHydrationTargetCountValid}
                  data-testid="add-hydration-target"
                  styles={{ root: { marginTop: "28px" } }} 
                />
              </Stack>
            </>
          )}
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            text="Submit"
            onClick={(handleSubmit)}
            disabled={
              submittingManagePoolRequest ||
              !isCostValid ||
              !isTargetPoolSizeValid ||
              !isDailyQuotaValid
            }
            data-testid="submit-button"
            styles={{ root: { marginTop: "10px" } }} />
        </Stack>
      {submittingManagePoolRequest && (
        <Spinner label='Creating pool...' ariaLive="assertive" /> // ANNA: hmm how to get the action in here
      )}
      {defaultMessage.length > 0 && messageType !== null && (
        <ExtendableMessageBar
          messageType={messageType}
          shortMessage={defaultMessage}
          additionalMessage={additionalMessage}
          data-testid="error-message-bar"
        />
      )}{" "}
      </>
    );
  };
  
  export default CreateOrUpdateAssetPool;