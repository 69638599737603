import { IExtendedColumn } from "../common/ExtendedDetailsList";
import { FilterType } from "../common/filterUtils";
import { FormatTime } from "../common/tableUtils";
import { ITestResource } from "../../constants/interfaces";
import { BooleanIcon } from "../common/BooleanIcon";

export const TestResourcesColumns: IExtendedColumn[] = [
  {
    key: "name",
    name: "User Principal Name",
    fieldName: "name",
    filterType: FilterType.DropDown,
    minWidth: 200,
    maxWidth: 350,
    isResizable: true,
    filterDefault: [],
  },
  {
    key: "TestAssetId",
    name: "TestAssetId",
    fieldName: "TestAssetId",
    filterType: FilterType.Text,
    minWidth: 50,
    maxWidth: 80,
    isResizable: true,
    onRender: (testResource: ITestResource) =>
      testResource.properties.TestAssetId,
  },
  {
    key: "profileName",
    name: "Profile Name",
    fieldName: "profileName",
    filterType: FilterType.DropDown,
    minWidth: 120,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "TenantId",
    name: "TenantId",
    fieldName: "TenantId",
    filterType: FilterType.Text,
    minWidth: 120,
    maxWidth: 150,
    isResizable: true,
    onRender: (testResource: ITestResource) => testResource.properties.TenantId,
  },
  {
    key: "ExpirationDate",
    name: "Expiration Date",
    fieldName: "ExpirationDate",
    filterType: FilterType.DateRange,
    isSortable: true,
    isSorted: true,
    onRender: (testResource: ITestResource) =>
      FormatTime(testResource.properties.ExpirationDate),
    isSortedDescending: false,
    minWidth: 120,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "IsAvailable",
    name: "Available",
    fieldName: "LeaseExpirationDate",
    minWidth: 80,
    maxWidth: 100,
    filterType: FilterType.Boolean,
    filterDefault: false,
    onRender: (testResource: ITestResource) => (
      <BooleanIcon value={testResource.properties.LeaseExpirationDate === ""} />
    ),
    isRowHeader: false,
  },
];
